import React from 'react'
import { navigate } from 'gatsby'

// import { Layout } from '@components/LayoutEN'
// import { SEO } from '@components/SEO'
// import { OurValues, WhatMakeUsDifferent } from '../screens/HomeEN'
// import {
//   BackgroundDivider,
//   CareerOpportunities,
//   Hero,
//   OurServices,
//   WhoWeAre,
// } from '../screens/Home'
import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import {
  BackgroundDivider,
  CareerOpportunities,
  Hero,
  OurServices,
  OurValues,
  WhatMakeUsDifferent,
  WhoWeAre,
} from '../screens/Home'

const IndexPageEN = () => {
  if (typeof window !== 'undefined' && window.location.pathname === '/') {
    navigate('/es')
  }

  // React.useEffect(() => {
  //   const initFreshChat = () => {
  //     window.fcWidget.init({
  //       token: '0aec75a9-b391-43f8-a1d8-8134240b736c',
  //       host: 'https://wchat.freshchat.com',
  //     })
  //   }

  //   const initialize = (i, t) => {
  //     let e
  //     i.getElementById(t)
  //       ? initFreshChat()
  //       : (((e = i.createElement('script')).id = t),
  //         (e.async = !0),
  //         (e.src = 'https://wchat.freshchat.com/js/widget.js'),
  //         (e.onload = initFreshChat),
  //         i.head.appendChild(e))
  //   }

  //   const initiateCall = () => {
  //     initialize(document, 'freshchat-js-sdk')
  //   }
  //   window.addEventListener
  //     ? window.addEventListener('load', initiateCall, !1)
  //     : window.attachEvent('load', initiateCall, !1)

  //   initiateCall()
  // }, [])

  return (
    <Layout>
      <SEO title="Aibrok" />
      <Hero
        title="Aibrok - ofrecemos las mejores soluciones"
        body="En Aibrok contamos siempre con líderes a nivel nacional e internacional. Nos centramos en la planificación integral a corto, medio y largo plazo, enfocada tanto al cliente particular como empresarial. La clave de nuestra actividad radica en el análisis y diseño de una estrategia basada en el análisis de la situación financiera y las coberturas de nuestros clientes."
      />
      <WhatMakeUsDifferent />
      <WhoWeAre
        TitleSection="Quienes Somos"
        titleM="Aibrok – Tu gestor"
        mision="Protección, previsión y las finanzas son una parte determinante de nuestra vida y la planificación de nuestros recursos es una herramienta clave para alcanzar nuestros objetivos."
        mision2="Sin embargo, necesitamos el conocimiento y experiencia de profesionales cualificados en la materia para tomar las decisiones idóneas en cada situación. "
        titleV="La mejor gestion se basa en la pasión y profesionalidad del equipo"
        vision="En Aibrok encontrarás solo consultores con un alto grado de compromiso y elevadas competencias que le asesoran con pasión y profesionalidad. Cada asesoramiento integral contiene sistemáticamente 3 fases diferenciadas."
        vision2=" Así, el cliente y su consultor personal encuentran – de forma sencilla y transparente – conjuntamente la solución que mejor se adapta. Los productos financieros y de seguros que se ofrecen han sido cuidadosamente seleccionados y corresponden a las mayores exigencias en calidad. Aibrok solo colabora con las entidades de mayor reputación y consolidación en el sector."
      />
      <BackgroundDivider />
      <OurValues />
      <OurServices
        TitleSection="Nuestros servicios"
        body1="El objetivo de Aibrok es encontrar las mejores soluciones para nuestros clientes."
        title="¿Cómo lo hacemos?"
        body2="Identificamos, analizamos y seleccionamos propuestas que le permitan optar por aquellas que cubran sus necesidades para cumplir sus expectativas a corto, medio y largo plazo."
        title2="La virtud está en la diversificación"
        body3="Amplia gama de productos externos para que el cliente siempre escoja la alternativa que más se adecúe a sus necesidades e intereses teniendo en cuenta que la diversificación es primordial para alcanzar el éxito."
        title3="Planificar es de sabios"
        body4="La planificación es determinante para garantizar el bienestar y asegurar una correcta gestión de tus recursos. Para una correcta planificación el primer paso es conocer las diferentes posibilidades existentes."
        cita="“La peor decisión es la indecisión”, Benjamín Franklin"
      />
      <CareerOpportunities
        TitleSection="Aibrok: Oportunidades de Desarrollo"
        title="Una carrera profesional donde los límites los pones tú"
        body1="La excelencia en la gestión de las personas es prioritaria para Aibrok y nuestro éxito se basa en tener un equipo dinámico y comprometido con los valores de la compañía."
        body2="En Aibrok creemos firmemente en el desarrollo del talento y tenemos claro que la formación y el desarrollo de nuestros profesionales es vital para nuestra compañía, por ello nos basamos en un sistema de meritocracia, donde cada empleado es único y se merece ser valorado de forma justa a través de un sistema de evaluación donde se tenga en cuenta su dedicación y sus logros."
        body3="Así, de forma continua se evalúa el desempeño, las perspectivas de crecimiento y se definen planes individuales de desarrollo que planifican los siguientes pasos que cada persona necesita para seguir creciendo en la empresa."
        title2="“La formación, nuestra mejor inversión”"
        body4="En Aibrok somos conscientes de que la formación es una gran inversión y por ello ofrecemos un plan de formación continuo y adaptado a las necesidades de nuestros empleados, con una amplia variedad temática y desde el primer momento de tu incorporación."
      />
      {/* <SEO title="Aibrok" />
      <Hero
        title="Aibrok - your financial consulting company"
        body={`At Aibrok, we focus on comprehensive, long-term and customer-oriented financial planning above all. The cornerstone of our financial consulting services is the analysis of the financial situation of our clients.`}
      />
      <WhatMakeUsDifferent />
      <WhoWeAre
        TitleSection="ABOUT US"
        titleM="Aibrok - Your Financial Consultant"
        mision=" Protection, foresight and finances in general are matters that greatly influence and determine life, whether we like it or not. However, few have sufficient control over it: the market is complex, there are countless options in products and supplier companies."
        mision2="Hence, it is very important to have an expert by your side to make the right decisions. For this it has us, Aibrok."
        titleV="The best advice is based on passion and professionalism"
        vision="At Aibrok you will find only highly competent and committed consultants who advise you with passion and are very clear about what they need to do. Each comprehensive advice systematically contains 3 differentiated phases. Thus, the client and his personal consultant find - in a simple and transparent way - together the solution that best suits."
        vision2=" The financial and insurance products offered have been carefully selected and meet the highest quality standards. Aibrok only cooperates with the most reputable and solid entities in the sector."
      />
      <BackgroundDivider />
      <OurValues />
      <OurServices
        TitleSection="OUR SERVICES"
        body1=" Aibrok's goal is to find the best solutions for our customers."
        title="How do we do it?"
        body2="We identify, analyze and select proposals that allow you to choose those that meet your needs to meet your expectations in the short, medium and long term."
        title2="The virtue is in the diversification"
        body3="Wide range of external products so that the client always chooses the alternative that best suits their needs and interests, bearing in mind that diversification is essential to achieve success."
        title3="Planning is wise"
        body4="Planning is essential to guarantee well-being and ensure proper management of your resources. For a correct planning the first step is to know the different existing possibilities."
        cita="“The worst decision is indecision”, Benjamín Franklin"
      />
      <CareerOpportunities
        TitleSection="Aibrok: DEVELOPMENT OPPORTUNITIES"
        title=" A professional career where the limits are set by you"
        body1="Excellence in people management is a priority for Aibrok and our success is based on having a dynamic team committed to the company's values."
        body2="At Aibrok we firmly believe in the development of talent and we are clear that the training and development of our professionals is vital for our company, which is why we rely on a meritocratic system, where each employee is unique and deserves to be valued in a way. fair through an evaluation system where their dedication and achievements are taken into account."
        body3="Thus, performance and growth prospects are continuously evaluated and individual development plans are defined that plan the next steps that each person needs to continue growing in the company."
        title2="“Training, our best investment”"
        body4="At Aibrok we are aware that training is a great investment and therefore we offer a continuous training plan adapted to the needs of our employees, with a wide variety of topics and from the first moment of your incorporation."
      /> */}
    </Layout>
  )
}

export default IndexPageEN
